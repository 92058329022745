<script setup>
const route = useRoute()

const leasingParams = useLeasingParams()
const state = reactive(leasingParams.getCurrentParams().client)
const stateFromQuery = route.query?.is_company

const computedState = computed(() => {
  if (stateFromQuery) {
    return stateFromQuery === '1' ? 'entrepreneur' : 'consumer'
  }
  return state
})

const changeState = (val) => {
  leasingParams.setCurrentParams({'client': val})
}
const showToggle = computed(()=> {
  const allow = [
    'index',
    'brand',
    'search',
    'collections',
    'favorites'
  ];
  return allow.includes(route.name);
})
</script>

<template>
  <InputSwitch v-if="showToggle"
    value="consumer"
    valueLeft="entrepreneur"
    :valueStart="computedState"
    label="Prywatnie"
    left-label="Dla firmy"
    class="lg:ml-auto"
    @changed="(val) => changeState(val)"
  />
</template>
