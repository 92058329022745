<script setup>
const props = defineProps({
  label: String,
  leftLabel: String,
  value: [String, Number],
  valueLeft: [String, Number],
  valueStart: [String, Number]
});

const emits = defineEmits(['changed']);

const position = ref(props.valueStart);

watch(() => position.value, (newPosition) => emits('changed', newPosition));

</script>

<template>
  <div class="flex cursor-pointer select-none gap-2 text-gray-400">
    <div v-if="props.leftLabel"
         @click="position = props.valueLeft"
         :class="[{'text-azure': position === props.valueLeft, 'font-extralight': position !== props.valueLeft}]"
         class="flex items-center text-[14px]"
    >{{ leftLabel }}
    </div>
    <div @click="position = position === props.valueLeft ? props.value : props.valueLeft"
         class="flex items-center"
    >
      <div class="relative rounded-full bg-azure w-8 h-4 shrink-0 items-center">
        <div class="absolute left-0.5 top-0.5 w-3 h-3 rounded-full bg-white transition-all"
             :class="[{'left-[2px]': position === props.valueLeft}, {'left-[16px]': position === props.value}]"
        ></div>
      </div>
    </div>
    <div @click="position = props.value"
         :class="[{'text-azure': position === props.value, 'font-extralight': position !== props.value}]"
         class="flex items-center text-[14px]"
    >{{ label }}
    </div>
  </div>
</template>
